import React from "react";
import Content from "../components/templates/content";
import Panel from "../components/templates/panel";
import Ribbon from "../components/templates/ribbon";
import Proposal from "../components/templates/proposal";
import Layout from "../components/layout";
import SEO from "../components/seo";

import Login from "../components/login";

const PurposePage = () => (
  <Layout>
    <Login />
    <SEO title="Places need purpose" />
    <Panel imgLink={"/images/purpose-1-header.png"}>
      <div className="purpose mobile-hide">
        <h1>
          Places with purpose do better: how we see the opportunity at Downtown
          West.
        </h1>
      </div>
    </Panel>
    <div className="mobile-show desktop-hide">
      <Ribbon bgColor="black">
        <h1>
          Places with purpose do better: how we see the opportunity at Downtown
          West.
        </h1>
        <br></br>
      </Ribbon>
    </div>

    <Content>
      <div className="container">
        <h2 className="compress">
          {" "}
          Downtown West is a significant step in the transformation of San Jose
          and its downtown. The opportunity is an exciting one — to bring
          together Google, businesses and local organizations, and inspire
          generations of people locally, nationally and internationally.
        </h2>
        <div className="breaker-thin"></div>

        <div className="compress">
          <h2>Why should anyone care?</h2>
          <p>
            <br></br>
            In order to really resonate with your audiences internally and
            externally — whether with a film or as the project progresses — you
            need to clearly articulate <u>why</u> you’re creating this place and
            how it will benefit people.
            <br></br>
            <br></br>
            Before a logo or storyboard, we like to work together to really
            answer: “why should anyone care?”
            <br></br>
            <br></br>
            To establish what we call <u>Place Purpose</u>.<br></br>
            <br></br>
          </p>
        </div>
        <div className="row no gutters">
          <div className="col-md-4">
            <img src="/images/content-img-purpose-1.png" width="100%" />
          </div>
          <div className="col-md-8">
            <img src="/images/content-img-purpose-2.png" width="100%" />
          </div>
        </div>
        <div className="breaker"></div>
        <div className="compress">
          <h2>What is Place Purpose?</h2>
          <br></br>
          <p>
            Place Purpose is the essential reason a place exists based on a
            human-centred needs.
            <br></br>
            <br></br>
            It’s what we’re doing for the customer, the community, the world.
            And it’s a promise that we’ll keep doing it better than anyone else.
          </p>
        </div>
      </div>
      <br></br>
      <img src="/images/content-img-purpose-3.png" width="100%" />
      <Ribbon>
        <br></br>
        <h2>What will a Place Purpose and brand do?</h2>
        <h3>
          — Galvanise and unite the team<br></br>— Ensure relevance to your
          audiences<br></br>— Be relevant to your audiences<br></br>— Underpin
          your broader vision<br></br>— Inspire desirability and loyalty
          <br></br>— Set you apart from competition<br></br>— Increase asset’s
          value<br></br>{" "}
        </h3>{" "}
        <br></br>
      </Ribbon>
      <div className="container">
        <div className="breaker"></div>
        <div className="compress">
          <h2>Most importantly</h2>
          <br></br>
          <h2>
            Over the course of the Downtown West project, you’re going to be
            making dozens of decisions each day. How do you ensure that each
            decision contributes in the right way?
            <br></br>
            <br></br>
            Place Purpose is the decision funnel for everything you do.
          </h2>
        </div>
      </div>
    </Content>
    <Proposal
      fwd={true}
      bck={true}
      linkBack={"about-us"}
      titleBack={"About us"}
      titleTo={"Downtown West"}
      linkTo={"vision"}
    />
  </Layout>
);

export default PurposePage;
